import React from "react"
import { graphql } from "gatsby"

import { Collection as Template } from "../components/Collection/Collection"

export const query = graphql`
  query($handle: String!) {
    collection: sanityCollection(shopify: { shopifyHandle: { eq: $handle } }) {
      ...GatsbySanityCollectionFragment
    }
    template: sanityTemplateCollection {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default ({ data, ...props }): JSX.Element => {
  return <Template {...props} {...data} />
}
