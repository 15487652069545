import tw, { styled } from "twin.macro"

const styles = {
  align: {
    left: tw`text-left`,
    center: tw`text-center`,
    right: tw`text-right`,
  },
}

export const Wrapper = styled.div`
  ${({ align }) => styles.align[align]}
  ${tw`max-w-xl hd:max-w-xxl  mx-auto py-2`}
`

export const ImageWrapper = styled.div`
  ${tw`relative pb-1 md:pb-2`}
`