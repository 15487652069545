import tw, { styled } from 'twin.macro';
import { StyledContainer } from '../../Styled/Container';


export const GridViewWrapper = styled(StyledContainer)`
  ${tw`hidden md:flex flex-row justify-end gap-1 py-1 pr-0 max-w-full hd:max-w-full`}
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
`

export const SquareIcon = styled.div`
  ${tw`cursor-pointer`}
  ${({ gridView }) => gridView ? `
  > span >  svg {
      stroke: #000;
      fill: transparent !important;
    }
  ` : `
  > span >  svg {
      fill: #000;
  }
  `}
`

export const CrossIcon = styled.div`
  ${tw`cursor-pointer`}
  ${({ gridView }) => gridView ? `
  > span >  svg {
      stroke: #000 !important;
      fill: unset !important;
  }
  ` : `
  > span >  svg {
    fill: transparent;
    stroke: #000;
  }
  `}
`