import React from 'react'
import { ImageVideo } from '../../ImageVideo/ImageVideo'

import { Heading4Serif } from '../../Styled/Text'
import { Wrapper } from './CollectionBannerStyles'
import { RichText } from '../../RichText/RichText'

const CollectionBanner = ({ collectionBanner }) => {
  const hasTitle = collectionBanner?.title?.length > 0
  const hasDescription = collectionBanner?.description?.length > 0
  
  return (
    <>
     {(hasDescription || hasTitle) && (
        <Wrapper align={collectionBanner?.textAlign}>
          {collectionBanner?.title && <Heading4Serif spacing="default">{collectionBanner?.title}</Heading4Serif>}
          <RichText align={collectionBanner?.textAlign} spacing="default">{collectionBanner?.description}</RichText>
        </Wrapper>
        )}
      {collectionBanner?.images && <ImageVideo imageVideo={collectionBanner?.images} />}
    </>
  )
}

export default React.memo(CollectionBanner)