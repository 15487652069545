import React from "react"

import { withCollection } from "./withCollection"
import { Wrapper, SEOWrapper, InnerWrapper } from "./CollectionStyles"
import { SearchResults } from "../Search/Results/SearchResults"
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs"
import { CollectionViews } from "./Views/CollectionViews"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"
import config from "../../../config.default"
import CollectionBanner from "./CollectionBanner/CollectionBanner"
import { RichText } from "../RichText/RichText"
import { GridViewToggle } from "./GridView/GridViewToggle"

export const Collection = withCollection(
  ({ title, handle, paths, gender = "unisex", collectionBanner }): JSX.Element => (
    <>
      <BreadCrumbs paths={paths} fullWidth={true}>
        <InnerWrapper>
          <CollectionViews gender={gender} />
          <GridViewToggle></GridViewToggle>
        </InnerWrapper>
      </BreadCrumbs>
      <CollectionBanner collectionBanner={collectionBanner} ></CollectionBanner>
      <Wrapper>
        <ReactifySearchProvider
          mode="collection"
          collectionHandle={handle || ""}
          shopifyPermanentDomain={config?.stores[config?.services?.shopify?.defaultShopName]?.shopifyShopDomain}
        >
            <Sensors />
            <SearchResults collection={{ title, handle }} />
        </ReactifySearchProvider>
      </Wrapper>
      { collectionBanner?.seoDescription && <SEOWrapper>
        <RichText align="center" spacing="SEO">{collectionBanner?.seoDescription}</RichText> 
      </SEOWrapper>}
    </>
  )
)
