import React from "react"
import { withCollectionViews } from "./withCollectionViews"
import { View, Wrapper } from "./CollectionViewsStyles"

export const CollectionViews = withCollectionViews(
  ({ handleClick, activeCollectionCardView, modelViewKey, productViewKey, modelViewText, productViewText, showMale, showFemale }) => (
    <Wrapper>
      {showMale && (
        <View onClick={() => handleClick(productViewKey)} active={activeCollectionCardView === productViewKey}>
        <span>{productViewText}</span>
      </View>
      )}
      {showFemale && (
        <View onClick={() => handleClick(modelViewKey)} active={activeCollectionCardView === modelViewKey}>
          <span>{modelViewText}</span>
        </View>
      )}
    </Wrapper>
  )
)
