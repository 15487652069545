import React, { useCallback } from "react"
import { useApp } from "../../../hooks/useApp"

export const withGridViewToggle = Component => ({ name = "GridViewToggle" }) => {

  const { gridView, setGridView } = useApp()

  const handleColumns = useCallback(() => {
    if (!gridView) return
    setGridView(false)
  }, [gridView, setGridView])

  const handleGrids = useCallback(() => {
    if (gridView) return
    setGridView(true)
  }, [gridView, setGridView])

  Component.displayName = name
  return <Component
    gridView={gridView}
    setGridView={setGridView}
    handleGrids={handleGrids}
    handleColumns={handleColumns} />
}