import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  max-w-full mx-auto
`

export const Title = tw.div`
  font-bold uppercase text-30 leading-none  md:text-60 md:leading-none text-center mb-3 md:mb-4-8
`

export const BreadCrumbs = tw.div`
  text-12 leading-none mb-1-6 md:mb-4 text-center
`

export const Panel = tw.div`
  grid grid-cols-1 mlg:grid-cols-3 items-center justify-between mb-3-2
`

export const Divider = tw.hr`hidden mlg:block`

export const FilterControl = styled.button<FilterControlProps>`
  ${tw`border-transparent rounded px-1-6 py-1-2 mlg:py-0-8 text-18 leading-none mlg:text-12 mlg:leading-none  flex justify-center mlg:justify-between items-center uppercase mlg:max-w-18 relative`}
  ${({ active }) => (active === "true" ? tw`bg-black text-white` : tw`bg-beige text-black mlg:hover:bg-beige border-beige`)}
`

export const IconWrapper = tw.span`
  mlg:pl-1-2 absolute mlg:static right-1-6
`

export const Stats = styled.p<StatsProps>`
  ${tw`text-center`}
  ${({ mobile }) => (mobile === "true" ? tw`mlg:hidden mb-3-2` : tw`hidden mlg:block`)}
`

export const SEOWrapper = styled.div`
  ${tw`pt-2 pb-1`}
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`

export const InnerWrapper = styled.div`
  ${tw`flex `}
`

type StatsProps = {
  mobile?: "true" | "false"
}

type FilterControlProps = {
  active: "true" | "false"
}
