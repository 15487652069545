import React from "react"

import { withGridViewToggle } from "./withGridViewToggle"
import { SquareIcon, CrossIcon, GridViewWrapper } from "./GridViewToggleStyles"
import { Icon } from "../../Icon/Icon"

export const GridViewToggle = withGridViewToggle(
  ({ gridView, handleColumns, handleGrids }): JSX.Element => (
    <GridViewWrapper>
      <SquareIcon gridView={gridView} onClick={() => handleColumns()}>{gridView ?  <Icon name={"columnOff"} width={15} height={15} /> : <Icon name={"columnOn"} width={15} height={15} /> }</SquareIcon>
      <CrossIcon gridView={gridView} onClick={() => handleGrids()}>{gridView ? <Icon name={"gridOn"} width={15} height={15} /> : <Icon name={"gridOff"} width={15} height={15}/>}</CrossIcon>
      
    </GridViewWrapper>
  )
)
