import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { useApp } from "../../../hooks/useApp"
import { useCore } from "../../../hooks/useCore"

export const withCollectionViews = Component => ({ name = "CollectionViews", gender }) => {
  const {
    globalStateReducer,
    config: {
      settings: { keys, constraints },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()

  const { collection } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_COLLECTION_VIEW {
      collection: sanityTemplateCollection {
        modelViewText
        productViewText
      }
    }
  `)

  const { modelViewText, productViewText } = collection || {}

  const [{ activeCollectionCardView }, dispatch] = globalStateReducer

  const handleClick = payload => {
    storage.set(keys?.collectionCardView, payload)
    dispatch({
      type: "CHANGE_COLLECTION_CARD_VIEW",
      payload: payload,
    })
  }

  const showMale = (gender === "male" || gender === "unisex") || false
  const showFemale = (gender === "female" || gender === "unisex") || false
  
  useEffect(() => {
    if(gender === "female") {
      handleClick(constraints?.FEMALE_VIEW)
    }
    if(gender === "male") {
      handleClick(constraints?.MALE_VIEW)
    }
    if (gender === "unisex" || !gender) {
      handleClick(constraints.UNISEX_VIEW)
    }
  }, [gender])

  Component.displayName = name
  return (
    <Component
      handleClick={handleClick}
      activeCollectionCardView={activeCollectionCardView}
      modelViewKey={constraints?.FEMALE_VIEW}
      productViewKey={constraints?.MALE_VIEW}
      modelViewText={modelViewText}
      productViewText={productViewText}
      showMale={showMale}
      showFemale={showFemale}
    />
  )
}
