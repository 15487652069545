import React from "react"

import { useRoutes } from "../../hooks/useRoutes"

export const withCollection = Component => ({ name = "Collection", collection }) => {
  const { linkResolver } = useRoutes()

  const parentCollection = linkResolver(collection.parentCollection)

  const paths = parentCollection?.title ? [parentCollection, { title: collection.title }] : [{ title: collection.title }]
  
  Component.displayName = name
  return <Component title={collection.title} handle={collection.shopify.handle} paths={paths} gender={collection?.gender || "unisex"} collectionBanner={collection?.collectionBanner}  />
}
