import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  flex flex-row
`

export const View = styled.button`
  ${tw`relative text-12 mx-0-8`}
  span {
    &:after {
      content: "";
      ${({ active }) => (active ? tw`bg-black` : tw`bg-transparent`)}
      ${tw`absolute  -bottom-0-4 left-0 w-full h-0-1`}
    }
  }
`
